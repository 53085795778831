import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ArchiveItem from './ArchiveItem'
import { imageLoadedStatus } from '../misc/utility.js'


//Component
class OtherPosts extends Component {

  containsObjectSlug = (current_list, list) => {

    for (let i = 0; i < current_list.length; i++) {
    	for (let j = 0; j < list.length; j++) {
    		if (current_list[i].slug === list[j].slug) {
         	return true
      	}
    	}
    }

    return false
  }

  shuffleArray = (array) => {

  	for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
    }

  	return array
  }

	render () {

		const {className, categories, darkFilter, titleOnlyItem, current_id } = this.props
		const current_categories = categories
		const title = ( this.props.title ) ? this.props.title : 'Liknande innehåll'
		const cases_to_show = ( typeof window !== 'undefined' && window.matchMedia("(max-width: 991px)").matches ) ? 2 : 3

		const posts = this.shuffleArray( this.props.posts.edges ).filter((node, index) => {

  		node = node.node
      let node_categories = null;

      if ( node.wordpress_id === current_id ) {
      	return false
      }

      if ( node.categories ) {
        node_categories = node.categories

      } else if ( node.cpt_categories ) {
        node_categories = node.cpt_categories

      }

      return this.containsObjectSlug( current_categories, node_categories )

    }).slice( 0, cases_to_show )

	  const classes = [
			'section',
	    'fp-auto-height-responsive',
	    'block-OtherPosts',
	    ( className ) ? className : null
	  ]

	  if ( posts.length > 0 ) {

	  	return (
		  	<section className={classes.join(' ')}>
			    <div className="component component-OtherPosts">

			    	<div className="container-fluid">

			    		<div className="row">

			    			<div className="col-12 mb-1">
			    				<hr/>
			    			</div>

			    			<div className="col-12 text-center mb-2">
			    				<h3 className="font-main size-h1-heading" dangerouslySetInnerHTML={{__html: title }}/>
			    			</div>

			    		</div>

			    		<div className="row">

					      {posts.map( (node , index) => {
					      		
					      		let item_container_classes = [
							        'col-12',
							        'col-sm-6',
							        ( posts.length >= 3 ) ? 'col-lg-4' : null,
							        'mb-3',
							        'mb-sm-1'
							      ]

						      	const item = node.node
						      	const title = ( item.acf.heading ) ? item.acf.heading : item.title
						      	const fixed = ( item.acf.main_image && item.acf.main_image.localFile ) ? item.acf.main_image.localFile.childImageSharp.fixed : null

						      	//Image console
									  imageLoadedStatus('OtherPosts.js', (!!fixed), item.acf.main_image.alt_text)

						      	return (
						      		<div key={index} className={item_container_classes.join(' ')}>
							      		<ArchiveItem 
							      			link={item.link}
				                  title={title}
				                  label= {item.acf.label}
				                  fixed={fixed}
				                  darkFilter={darkFilter}
				                  titleOnlyItem={titleOnlyItem}
							      		/>
						      		</div>
						      	)
					      })}

				       </div>
		    		</div>

		    	</div>
			  </section>
		  )

	  } else {
	  	return null

	  }
	}
}


//Props validation
OtherPosts.propTypes = {
  className: PropTypes.string
}


//Export
export default OtherPosts