import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import ComponentImageWrapper from '../components/ComponentImageWrapper'
import Text from '../components/Text'
import TextLinks from '../components/TextLinks'
import SingleCategories from '../components/SingleCategories'
import FlexibleContent from '../components/FlexibleContent'
import OtherPosts from '../components/OtherPosts'


//Component
const PressTemplate = ( props ) => {

  const current_page = props.data.wordpressPost
  const other_pages = props.data.allWordpressPost
  const yoast_meta = current_page.yoast_meta
  
  const current_meta = {
    meta_title:     (yoast_meta) ? yoast_meta.yoast_wpseo_title : null,
    meta_desc:      (yoast_meta) ? yoast_meta.yoast_wpseo_metadesc : null,
  }

  const image_url = ( current_page.acf.main_image && current_page.acf.main_image.localFile ) ? current_page.acf.main_image.localFile.childImageSharp.fluid.src : null
  const categories = current_page.categories
  const flexible_content = current_page.acf.content_post

  return (
    <Layout 
      current_meta = {current_meta} 
    >

      <ComponentImageWrapper
        component = "ImageIntro"
        image_url = {image_url}
      >
        <div className="container-fluid text-center align-items-center">

          <Text>
            {current_page.title ? (
              <h1 className="size-h1-heading font-main" dangerouslySetInnerHTML={{__html: current_page.title }} />
            ) : null}

            {current_page.acf.excerpt_main_image ? (
              <TextLinks className="size-lg-text" content={current_page.acf.excerpt_main_image} />
            ) : null}
          </Text>

          
          {categories ? (
            <SingleCategories 
              title="KATEGORIER"
              items={categories}
            />
          ) : null}

        </div>
      </ComponentImageWrapper>

      {flexible_content ? ( 
        <FlexibleContent 
          use_first = {false}
          content = {flexible_content}
        />
      ) : null}

      {other_pages ? (
          <OtherPosts
            title="Mer som har hänt"
            categories={categories}
            current_id={current_page.wordpress_id}
            posts={other_pages}
            darkFilter={true}
            titleOnlyItem={true}
          />
        ) : null}

    </Layout>
  )
}


//Export
export default PressTemplate


//Query
export const pressQuery = graphql`
query currentPressQuery($id: String!) {
  wordpressPost(id: { eq: $id }) {
    wordpress_id
    title
    acf {
      excerpt_main_image
      main_image {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, maxHeight: 1500, cropFocus: CENTER) {
              ...Fluid
            }
          }
        }
      }
      content_post {
        ...contentFlexible
      }
    }
    categories {
      name
      slug
      link
    }
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
  }
  allWordpressPost {
    edges {
      node {
        wordpress_id
        title
        link
        acf {
          heading
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 900, height: 600, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        categories {
          slug
        }
      }
    }
  }
}
`