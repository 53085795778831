import React from 'react'
import PropTypes from 'prop-types'
import LinkDynamic from './LinkDynamic'


//Component
const SingleCategories = ({ items, title, tonedDown, className }) => {

  let classes = [
    'single-categories',
    ( tonedDown ) ? 'toned-down' : null,
    ( className) ? className : null
  ]

  return (
    <React.Fragment>
      {(items) ? (
        <div className={classes.join(' ')}>
          
          {( title ) ? 
            <p className="title" dangerouslySetInnerHTML={{__html: title }} />
          : null }

          <ul className="items">
            {items.map((node , index) => {
                return (

                  <li key={index} className="item">
                    <LinkDynamic 
                      to={node.link} 
                    >
                      {node.name}
                    </LinkDynamic>
                  </li>

                )
            })}
          </ul>
        </div>

      ) : null}
    </React.Fragment>
  )
}


//Props validation
SingleCategories.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
}


//Export
export default SingleCategories